const stepperValues = {
  'dados-pessoais': {
    title: 'Dados pessoais',
    stepNumber: '1'
  },
  nome: {
    title: 'Dados pessoais',
    stepNumberB: '1'
  },
  email: {
    title: 'Dados pessoais',
    stepNumberB: '2'
  },
  telefone: {
    title: 'Dados pessoais',
    stepNumberB: '3'
  },
  'grupo-necessidade': {
    title: 'Necessidade',
    stepNumber: '2',
    stepNumberB: '4'
  },
  'grupo-necessidade/outros': {
    title: 'Necessidade',
    stepNumber: '2',
    stepNumberB: '4'
  },
  'verificacao-token-sms': {
    title: 'Token',
    stepNumber: '5',
    stepNumberB: '7'
  },
  'verificacao-codigo-sms': {
    title: 'Token',
    stepNumber: '7',
    stepNumberB: '9'
  },
  'verificacao-codigo-smsA': {
    title: 'Token',
    stepNumber: '5',
    stepNumberB: '7'
  },
  'valor-desejado': {
    title: 'Valor do empréstimo',
    stepNumber: '5',
    stepNumberB: '7'
  },
  'valor-desejadoA': {
    title: 'Valor do empréstimo',
    stepNumber: '2',
    stepNumberB: '4'
  },
  motivo: {
    title: 'motivo',
    stepNumber: '3',
    stepNumberB: '5'
  },
  'motivo/customizar': {
    title: 'motivo',
    stepNumber: '3',
    stepNumberB: '5'
  },
  necessidade: {
    title: 'Necessidade',
    stepNumber: '3',
    stepNumberB: '5'
  },
  profissao: {
    title: 'Fonte de renda',
    stepNumber: '4',
    stepNumberB: '6'
  },
  'fonte-de-renda': {
    title: 'Fonte de renda',
    stepNumber: '4',
    stepNumberB: '6'
  },
  'motivo-emprestimo': {
    title: 'Motivo do empréstimo',
    stepNumber: '5',
    stepNumberB: '7'
  },
  diagnostico: {
    title: 'Diagnóstico',
    stepNumber: '6',
    stepNumberB: '8'
  },
  'dados-imovel': {
    title: 'Imóvel',
    stepNumber: '7',
    stepNumberB: '9'
  },
  'dados-imovel/customizar': {
    title: 'Imóvel',
    stepNumber: '7',
    stepNumberB: '9'
  },
  'dados-imovel/indisponivel': {
    title: 'Imóvel',
    stepNumber: '7',
    stepNumberB: '9'
  },
  'dados-pessoais-formalizacao': {
    title: 'Dados pessoais',
    stepNumber: '1'
  },
  'formalizacao-documento': {
    title: 'Documento',
    stepNumber: '2'
  },
  'endereco-residencial': {
    title: 'Endereço',
    stepNumber: '3'
  },
  'dados-bancarios': {
    title: 'Dados bancários',
    stepNumber: '4'
  },
  'emprestimo-recorrente': {
    title: 'Opção de empréstimo',
    stepNumber: '4'
  },
  objetivo: {
    title: 'Objetivo',
    stepNumber: '6',
    stepNumberB: '8'
  }
}

export default stepperValues
