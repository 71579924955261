import { GA_KEY as gaKey } from 'gatsby-env-variables'

import analytics from '@mobi/libraries/analytics'
import feature from '@mobi/libraries/feature-toggle'
import configure from 'itau-jn6-dep-www/packages/ds/configure'
import { segment, trackings, windowTrackClick, datadog } from 'utils'

import 'utils/bootstrap'

import './src/styles/index.scss'
import wrapWithProvider from './wrap-with-provider'

analytics.setup({
  GTMId: gaKey,
  trackings,
  makeTagular: {},
  isSDKTrackings: true
})

feature.setup({
  definitions: {
    dev: process.env.BUILD_ENV === 'development',
    e2e: process.env.BUILD_ENV === 'e2e',
    stg: process.env.BUILD_ENV === 'staging',
    prod: process.env.BUILD_ENV === 'production'
  },
  features: {}
})

datadog.init()
datadog.logsInit()

configure()

export const onRouteUpdate = (locationProps) => {
  const clicksCallback = (currentButton) => {
    segment.buttonClick({ currentButton, locationProps })
  }
  segment.init(locationProps)
  windowTrackClick(clicksCallback)
}

export const wrapRootElement = wrapWithProvider
