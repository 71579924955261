const occupationDictionary = {
  retired: 'INSS',
  'government-employee': 'FuncionarioPublico',
  'self-employed': 'ProfissionalLiberal',
  employee: 'Assalariado',
  entrepreneur: 'EmpresarioOuAutonomo',
  unemployed: 'Desempregado',
  'siape-federal-employee': 'ServidorFederal',
  'municipal-employee': 'FuncionarioPublicoMunicipal',
  'state-employee': 'FuncionarioPublicoEstadual',
  others: 'Outros'
}

export default occupationDictionary
