import {
  digitalVariables,
  getSourceUrlWithQuerys,
  formatters,
  storage
} from 'utils'

import auth from './auth'
import { defaultLeadsApiCall } from './utils'

const productId = 0

const newCreateLead = async ({
  name,
  cpf,
  email,
  phone,
  recaptcha,
  partner,
  variant,
  testName,
  ...args
}) => {
  const additionalData = await digitalVariables.get()
  const requestBody = {
    lead_name: name,
    document_number: cpf,
    email: email,
    phone_number: phone,
    product_id: productId,
    source_url: getSourceUrlWithQuerys(variant, testName),
    partner,
    additional_data: additionalData,
    ...args
  }

  const request = await auth({}).then((_http) =>
    _http.post(`v2/itau-360-shopping-api/leads/create`, requestBody)
  )

  const { data } = request

  const token = {
    accessToken: data.auth['token'],
    startDate: data.auth['server_time'],
    validTime: data.auth['valid_time'],
    leadId: data.id
  }

  storage.session.setItem('token', JSON.stringify(token))

  return data
}

const createLead = async ({
  name,
  cpf,
  email,
  phone,
  recaptcha,
  partner,
  optimizeFlow
}) => {
  const additionalData = await digitalVariables.get()
  const requestBody = {
    lead_name: name,
    document_number: cpf,
    email: email,
    phone_number: phone,
    product_id: productId,
    source_url: getSourceUrlWithQuerys(optimizeFlow),
    partner,
    additional_data: additionalData
  }

  const request = await auth({}).then((_http) =>
    _http.post(`v2/itau-360-shopping-api/leads/`, requestBody, {
      headers: { 'g-recaptcha-response': recaptcha }
    })
  )

  const { data } = request

  const token = {
    accessToken: data.auth['token'],
    startDate: data.auth['server_time'],
    validTime: data.auth['valid_time'],
    leadId: data.id
  }

  storage.session.setItem('token', JSON.stringify(token))

  return data
}

const getProposals = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'proposals',
    method: 'get'
  })

  return data
}

const getDiagnosis = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'target-user-goal',
    method: 'get'
  })

  return data
}

const finishSimulation = async (requestBody = {}) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'simulation_summary',
    method: 'post',
    body: requestBody
  })

  return data
}

const editProposalData = async (offerUuid, requestBody) => {
  const formatedBody = {
    loan_value: formatters.number.currencyToFloat(requestBody.value),
    ...(requestBody.installmentsValue && {
      installments_value: formatters.number.currencyToFloat(
        requestBody.installmentsValue.toString()
      )
    }),
    ...(requestBody.installmentsNumber && {
      installments_number: Number(
        formatters.misc.removeNonNumericCharacters(
          requestBody.installmentsNumber.toString()
        )
      )
    }),
    ...(requestBody.dueDate && {
      due_date: formatters.date.toISO(requestBody.dueDate)
    })
  }

  const { data } = await defaultLeadsApiCall({
    endpoint: `${offerUuid}/simulations`,
    method: 'put',
    body: formatedBody
  })

  return data
}

const crossSell = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'cross-sell',
    method: 'post'
  })

  const token = {
    accessToken: data.auth['token'],
    startDate: data.auth['server_time'],
    validTime: data.auth['valid_time'],
    leadId: data.id
  }

  storage.session.setItem('token', JSON.stringify(token))

  return data?.next_step
}

const getLeadPhone = async (args) => {
  const isToRemoveHash = args?.removeHash
  const hashFlag = isToRemoveHash ? '?hash=false' : ''

  const { data } = await defaultLeadsApiCall({
    endpoint: `send_sms_code/${hashFlag}`,
    method: 'get'
  })

  return data
}

const prospect = async ({ product_code, cpf }) => {
  const additionalData = await digitalVariables.get()
  const requestBody = {
    document_number: cpf,
    product_code: product_code,
    source_url: getSourceUrlWithQuerys(),
    additional_data: additionalData
  }

  await auth({}).then((_http) =>
    _http.post(`v2/itau-360-shopping-api/leads/prospect`, requestBody)
  )
}

export default {
  newCreateLead,
  createLead,
  finishSimulation,
  editProposalData,
  crossSell,
  getLeadPhone,
  prospect,
  getProposals
}
