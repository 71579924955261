const occupation = [
  {
    label: 'Aposentado ou pensionista',
    id: 'retired',
    value: 'retired',
    icon: 'inss'
  },
  {
    label: 'Funcionário público',
    id: 'government-employee',
    value: 'government-employee',
    icon: 'gerente_outline'
  },
  {
    label: 'Profissional liberal',
    id: 'self-employed',
    value: 'self-employed',
    icon: 'usuario_outline'
  },
  {
    label: 'Assalariado',
    id: 'employee',
    value: 'employee',
    icon: 'cash_box_base'
  },
  {
    label: 'Empresário ou autônomo',
    id: 'entrepreneur',
    value: 'entrepreneur',
    icon: 'empresas_outline'
  },
  {
    label: 'Desempregado',
    id: 'unemployed',
    value: 'unemployed',
    icon: 'busca_outline'
  }
]

export default occupation
