import isClient from '../envs/isClient'

// Adicionar esse script em cada Variante dentro do VWO
// com o retorno de sua respectiva variante:

// window.addEventListener("load", () => {
// 	window.vwo_experiment = window.vwo_experiment || {};
// 	window.vwo_experiment.experimentName = "B";
// });

export default (experimentName) => {
  return new Promise((resolve, reject) => {
    const getVWOVariantInterval = setInterval(() => {
      try {
        if (!isClient()) return

        if (window?.vwo_experiment) {
          clearInterval(getVWOVariantInterval)
          const experiment = window?.vwo_experiment || {}
          const variant = experiment[experimentName] || 'A'
          const vwoData = { experiment: experimentName, variant }

          resolve(vwoData)
        }
      } catch (error) {
        clearInterval(getVWOVariantInterval)

        reject(error)
      }
    }, 100)
  })
}
