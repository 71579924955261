import { datadogLogs } from '@datadog/browser-logs'
import { isClient, storage } from 'utils'

const getDefaultInfo = () => {
  const anonymousId = isClient()
    ? window?.analytics?._user?.anonymousId()
    : 'unknown'
  const leadID = isClient()
    ? storage.session.getItem('token')?.leadId
    : 'unknown'
  const currentHref = isClient() ? window.location.href : 'unknown'
  const currentPath = isClient()
    ? window.location.pathname?.replaceAll('/', '')
    : 'unknown'

  return {
    anonymousId,
    leadID,
    currentHref,
    currentPath
  }
}

export const DDPageView = ({ pageName = '', payload = {} }) => {
  const defaultInfo = getDefaultInfo()
  const data = { ...defaultInfo, ...payload }

  datadogLogs.logger.info(`Page:${pageName}`, {}, data)
}

export const DDPageError = ({ pageName = '', name = '', payload = {} }) => {
  const defaultInfo = getDefaultInfo()

  const currentPageName = pageName || defaultInfo['currentPath']

  const data = { name, ...defaultInfo, ...payload }

  datadogLogs.logger.error(`Page:${currentPageName}`, {}, data)
}
