const purposeDictionary = {
  'pay-debits': 'ContasEDividas',
  shopping: 'FazerCompras',
  business: 'InvestirEmUmNegocio',
  property: 'BemAltoValor',
  education: 'FinanciarEstudos',
  others: 'Outros'
}

export default purposeDictionary
