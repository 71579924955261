const goalDictionary = {
  'buy-house': 'ComprarCasa',
  'make-purchase': 'FazerUmaCompra',
  'medical-costs': 'CobrirCustosMedicos',
  'take-trip': 'FazerUmaViagem',
  'pay-debt': 'PagarUmaConta',
  'buy-car': 'ComprarUmCarro',
  'invest-education': 'InvestirEmEducacao',
  others: 'Outros'
}

export default goalDictionary
