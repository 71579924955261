/* eslint-disable no-useless-escape */
const removeNonNumericCharacters = (value = '') => value.replace(/\D/g, '')
const toMonths = (value = '') => `${value} meses`
const identity = (value = '') => value

const toLowerSnakeCase = (value = '') => {
  if (!value) return

  const stringWithoutSpecialCharacter = value.replace(/[\ /-]+/g, '_')
  const stringInLowercase = stringWithoutSpecialCharacter.toLowerCase()

  return stringInLowercase
}

const toUpperCaseWithoutSpace = (value = '') => {
  const stringWithoutSpecialCharacter = value.replace(/[\ /-]+/g, '')
  const stringInUpperCase = stringWithoutSpecialCharacter.toUpperCase()

  return stringInUpperCase
}

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default {
  capitalize,
  removeNonNumericCharacters,
  toMonths,
  identity,
  toLowerSnakeCase,
  toUpperCaseWithoutSpace
}
