// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-allow-fgts-app-index-js": () => import("./../../../src/pages/AllowFGTSApp/index.js" /* webpackChunkName: "component---src-pages-allow-fgts-app-index-js" */),
  "component---src-pages-cross-sell-index-js": () => import("./../../../src/pages/CrossSell/index.js" /* webpackChunkName: "component---src-pages-cross-sell-index-js" */),
  "component---src-pages-error-index-js": () => import("./../../../src/pages/Error/index.js" /* webpackChunkName: "component---src-pages-error-index-js" */),
  "component---src-pages-formalization-address-index-js": () => import("./../../../src/pages/Formalization/Address/index.js" /* webpackChunkName: "component---src-pages-formalization-address-index-js" */),
  "component---src-pages-formalization-bank-data-index-js": () => import("./../../../src/pages/Formalization/BankData/index.js" /* webpackChunkName: "component---src-pages-formalization-bank-data-index-js" */),
  "component---src-pages-formalization-documentation-index-js": () => import("./../../../src/pages/Formalization/Documentation/index.js" /* webpackChunkName: "component---src-pages-formalization-documentation-index-js" */),
  "component---src-pages-formalization-finished-index-js": () => import("./../../../src/pages/Formalization/Finished/index.js" /* webpackChunkName: "component---src-pages-formalization-finished-index-js" */),
  "component---src-pages-formalization-personal-data-index-js": () => import("./../../../src/pages/Formalization/PersonalData/index.js" /* webpackChunkName: "component---src-pages-formalization-personal-data-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-offers-index-js": () => import("./../../../src/pages/Offers/index.js" /* webpackChunkName: "component---src-pages-offers-index-js" */),
  "component---src-pages-proposal-agreement-index-js": () => import("./../../../src/pages/Proposal/Agreement/index.js" /* webpackChunkName: "component---src-pages-proposal-agreement-index-js" */),
  "component---src-pages-proposal-basic-data-index-js": () => import("./../../../src/pages/Proposal/BasicData/index.js" /* webpackChunkName: "component---src-pages-proposal-basic-data-index-js" */),
  "component---src-pages-proposal-basic-data-teste-b-email-index-js": () => import("./../../../src/pages/Proposal/BasicData/TesteB/Email/index.js" /* webpackChunkName: "component---src-pages-proposal-basic-data-teste-b-email-index-js" */),
  "component---src-pages-proposal-basic-data-teste-b-name-index-js": () => import("./../../../src/pages/Proposal/BasicData/TesteB/Name/index.js" /* webpackChunkName: "component---src-pages-proposal-basic-data-teste-b-name-index-js" */),
  "component---src-pages-proposal-basic-data-teste-b-phone-index-js": () => import("./../../../src/pages/Proposal/BasicData/TesteB/Phone/index.js" /* webpackChunkName: "component---src-pages-proposal-basic-data-teste-b-phone-index-js" */),
  "component---src-pages-proposal-desired-loan-data-index-js": () => import("./../../../src/pages/Proposal/DesiredLoanData/index.js" /* webpackChunkName: "component---src-pages-proposal-desired-loan-data-index-js" */),
  "component---src-pages-proposal-diagnosis-index-js": () => import("./../../../src/pages/Proposal/Diagnosis/index.js" /* webpackChunkName: "component---src-pages-proposal-diagnosis-index-js" */),
  "component---src-pages-proposal-new-sms-code-verification-index-js": () => import("./../../../src/pages/Proposal/NewSmsCodeVerification/index.js" /* webpackChunkName: "component---src-pages-proposal-new-sms-code-verification-index-js" */),
  "component---src-pages-proposal-property-data-choose-index-js": () => import("./../../../src/pages/Proposal/PropertyData/Choose/index.js" /* webpackChunkName: "component---src-pages-proposal-property-data-choose-index-js" */),
  "component---src-pages-proposal-property-data-customize-index-js": () => import("./../../../src/pages/Proposal/PropertyData/Customize/index.js" /* webpackChunkName: "component---src-pages-proposal-property-data-customize-index-js" */),
  "component---src-pages-proposal-property-data-unavailable-index-js": () => import("./../../../src/pages/Proposal/PropertyData/Unavailable/index.js" /* webpackChunkName: "component---src-pages-proposal-property-data-unavailable-index-js" */),
  "component---src-pages-proposal-purpose-data-index-js": () => import("./../../../src/pages/Proposal/PurposeData/index.js" /* webpackChunkName: "component---src-pages-proposal-purpose-data-index-js" */),
  "component---src-pages-proposal-select-goal-customize-index-js": () => import("./../../../src/pages/Proposal/SelectGoal/Customize/index.js" /* webpackChunkName: "component---src-pages-proposal-select-goal-customize-index-js" */),
  "component---src-pages-proposal-select-goal-index-js": () => import("./../../../src/pages/Proposal/SelectGoal/index.js" /* webpackChunkName: "component---src-pages-proposal-select-goal-index-js" */),
  "component---src-pages-proposal-select-macro-goal-customize-index-js": () => import("./../../../src/pages/Proposal/SelectMacroGoal/Customize/index.js" /* webpackChunkName: "component---src-pages-proposal-select-macro-goal-customize-index-js" */),
  "component---src-pages-proposal-select-macro-goal-index-js": () => import("./../../../src/pages/Proposal/SelectMacroGoal/index.js" /* webpackChunkName: "component---src-pages-proposal-select-macro-goal-index-js" */),
  "component---src-pages-proposal-select-micro-goal-index-js": () => import("./../../../src/pages/Proposal/SelectMicroGoal/index.js" /* webpackChunkName: "component---src-pages-proposal-select-micro-goal-index-js" */),
  "component---src-pages-proposal-select-occupation-index-js": () => import("./../../../src/pages/Proposal/SelectOccupation/index.js" /* webpackChunkName: "component---src-pages-proposal-select-occupation-index-js" */),
  "component---src-pages-proposal-sms-code-verification-index-js": () => import("./../../../src/pages/Proposal/SmsCodeVerification/index.js" /* webpackChunkName: "component---src-pages-proposal-sms-code-verification-index-js" */),
  "component---src-pages-result-azul-infinite-card-index-js": () => import("./../../../src/pages/Result/AzulInfiniteCard/index.js" /* webpackChunkName: "component---src-pages-result-azul-infinite-card-index-js" */),
  "component---src-pages-result-azul-platinum-card-index-js": () => import("./../../../src/pages/Result/AzulPlatinumCard/index.js" /* webpackChunkName: "component---src-pages-result-azul-platinum-card-index-js" */),
  "component---src-pages-result-cgi-index-js": () => import("./../../../src/pages/Result/CGI/index.js" /* webpackChunkName: "component---src-pages-result-cgi-index-js" */),
  "component---src-pages-result-consignado-success-index-js": () => import("./../../../src/pages/Result/ConsignadoSuccess/index.js" /* webpackChunkName: "component---src-pages-result-consignado-success-index-js" */),
  "component---src-pages-result-consorcio-index-js": () => import("./../../../src/pages/Result/Consorcio/index.js" /* webpackChunkName: "component---src-pages-result-consorcio-index-js" */),
  "component---src-pages-result-fgts-success-index-js": () => import("./../../../src/pages/Result/FgtsSuccess/index.js" /* webpackChunkName: "component---src-pages-result-fgts-success-index-js" */),
  "component---src-pages-result-fgts-web-app-success-index-js": () => import("./../../../src/pages/Result/FgtsWebAppSuccess/index.js" /* webpackChunkName: "component---src-pages-result-fgts-web-app-success-index-js" */),
  "component---src-pages-result-itau-account-index-js": () => import("./../../../src/pages/Result/ItauAccount/index.js" /* webpackChunkName: "component---src-pages-result-itau-account-index-js" */),
  "component---src-pages-result-pao-de-acucar-card-index-js": () => import("./../../../src/pages/Result/PaoDeAcucarCard/index.js" /* webpackChunkName: "component---src-pages-result-pao-de-acucar-card-index-js" */),
  "component---src-pages-result-recovery-index-js": () => import("./../../../src/pages/Result/Recovery/index.js" /* webpackChunkName: "component---src-pages-result-recovery-index-js" */),
  "component---src-pages-result-reneg-index-js": () => import("./../../../src/pages/Result/Reneg/index.js" /* webpackChunkName: "component---src-pages-result-reneg-index-js" */),
  "component---src-pages-searching-credit-index-js": () => import("./../../../src/pages/SearchingCredit/index.js" /* webpackChunkName: "component---src-pages-searching-credit-index-js" */),
  "component---src-pages-skipper-index-js": () => import("./../../../src/pages/Skipper/index.js" /* webpackChunkName: "component---src-pages-skipper-index-js" */),
  "component---src-pages-sms-index-js": () => import("./../../../src/pages/Sms/index.js" /* webpackChunkName: "component---src-pages-sms-index-js" */)
}

