const loanPurpose = [
  {
    label: 'pagar contas ou dívidas',
    id: 'pay-debits',
    value: 'pay-debits',
    icon: {
      name: 'documento',
      type: 'outlined'
    }
  },
  {
    label: 'fazer compras',
    id: 'shopping',
    value: 'shopping',
    icon: {
      name: 'carrinho',
      type: 'outlined'
    }
  },
  {
    label: 'investir no meu negócio',
    id: 'business',
    value: 'business',
    icon: {
      name: 'loja',
      type: 'outlined'
    }
  },
  {
    label: 'comprar um bem de alto valor',
    id: 'property',
    value: 'property',
    icon: {
      name: 'home',
      type: 'outlined'
    }
  },
  {
    label: 'financiar meus estudos',
    id: 'education',
    value: 'education',
    icon: {
      name: 'universitarios',
      type: 'outlined'
    }
  },
  {
    label: 'outros',
    id: 'others',
    value: 'others',
    icon: {
      name: 'viagem',
      type: 'outlined'
    }
  }
]

export default loanPurpose
