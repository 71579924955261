import gaSetup from '@mobi/libraries/analytics/ga/setup'

import trackClick from './trackClick'
import trackPagename from './trackPagename'

const setup = () => {
  return gaSetup({ isSDKTrackings: true }).then(
    () => {
      console.log(`\x1b[32m GA4 - Script loaded \x1b[0m`)
    },
    () => {
      new Error('GA4 - Script loading failed')
    }
  )
}

/**
 * GA4 Pattern
 * - lower case string
 * - no accents
 * - no special characters
 * - white spaces replaced with a "-"
 */
export const normalizeParameters = (parameters) => {
  const normalizeValue = (value) =>
    String(value)
      .toLowerCase()
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .replace(/[^a-zA-Z0-9-/:]/g, '')

  const normalizedParameters = {}
  Object.entries(parameters).forEach(([key, value]) => {
    const hasData = value !== undefined && value !== null
    if (hasData) normalizedParameters[key] = normalizeValue(value)
  })
  return normalizedParameters
}

const tracking = {
  pageLoad: async ({ currentPage, customLayer }) => {
    await setup()
    window?.ItauDigitalAnalytics?.setDefaultParameters({
      analytics: {
        parameters: {
          business: 'pf',
          environment: 'nao-logado',
          business_channel: 'aquisicao-cartao'
        }
      }
    })
    const dataLayer = trackPagename({ currentPage, customLayer })

    const parameters = dataLayer.analytics.parameters
    dataLayer.analytics.parameters = normalizeParameters(parameters)

    window?.ItauDigitalAnalytics?.track(dataLayer)
  },
  buttonClick: async ({ currentPage, detail, customLayer }) => {
    try {
      await setup()

      const dataLayer = trackClick({ currentPage, detail, customLayer })
      const parameters = dataLayer.analytics.parameters
      dataLayer.analytics.parameters = normalizeParameters(parameters)

      window?.ItauDigitalAnalytics?.track(dataLayer)
    } catch (err) {
      console.log(err)
    }
  }
}

export default tracking
